/*
 * Manages the responsive site-navigation menus. A horizontal navigation-menu is
 * used when viewport wider than the breakpoint, and a vertical, hamburger menu
 * is used otherwise.
 *
 * Export
 *     initSiteNav
 *
 *  TOC
 *     INIT NAVIGATION
 *         STICKY NAV
 *     TABBED MENU
 *     HAMBURGER MENU
 *         INIT
 *         TOGGLE
 */
import { getElemHeight } from "@elems";
/** Module scoped data. */
const mScope = {
    breakpoint: 748,  /* between collapsed and expanded navigation */
    updateInProgress: false    /* present when window is being resized. */
};
/* ===================== INIT NAVIGATION ==================================== */
export function initSiteNav (): void {
    initViewportChangeListeners();
    toggleNavMenu();
    initStickyNav(); //Must load after image slider and nav init
}
function initViewportChangeListeners (): void {
    window.addEventListener( 'resize', onResizeToggleNav );
    window.addEventListener( "orientationchange", onResizeToggleNav );
}
/**
 * A horizontal navigation-menu is used when viewport wider than the breakpoint,
 * and a vertical, hamburger, menus is used otherwise.
 * Note: window.outerWidth is 0 when the page is loaded in a tab in chrome
 */
function toggleNavMenu () {
    if ( window.innerWidth >= mScope.breakpoint ) loadTabbedMenu();
    else loadHamburgerMenu();
}
function onResizeToggleNav (): void {
    if ( mScope.updateInProgress ) return;
    mScope.updateInProgress = true;
    window.setTimeout( () => {
        mScope.updateInProgress = false;
        toggleNavMenu();
    }, 500 );
}
/* --------------------- STICKY NAV ----------------------------------------- */
/* Header-menu sticks to the top when image header scrolls off screen. */
function initStickyNav (): void {
    $( window ).on( 'scroll', handleStickyNav );
    $( window ).trigger( 'scroll' );
}
export function handleStickyNav (): void {
    const sliderH = getElemHeight( '#header-image' );
    const scrollTop = $( window ).scrollTop();
    if ( scrollTop && scrollTop > sliderH ) {
        $( '#sticky-hdr' ).addClass( "top-sticky" );
        $( '#page-body' ).css( 'top', getPageBodyTop( sliderH ) );
    } else {
        $( '#sticky-hdr' ).removeClass( "top-sticky" );
        $( '#page-body' ).css( 'top', getElemHeight( '#site-header' ) );
    }
}
function getPageBodyTop ( sliderH: number ): number {
    return sliderH + getElemHeight( '#sticky-hdr' );
}
/* ======================== TABBED MENU ===================================== */
function loadTabbedMenu (): void {
    unsetHamburgerListeners();
    initTabbedStyles();
}
function initTabbedStyles (): void {
    // Remove hamburger styles
    $( '#mobile-menu-bar' ).hide();
    $( '#oimenu' ).removeClass( 'vert closed flex-col' );
    $( '#hdrmenu' ).removeClass( 'vert' );
    // Set tabbed styles
    $( 'nav' ).addClass( 'flex-row' );
    $( '#oimenu' ).addClass( 'flex-row' );
    $( '#full-menu-site-details' ).show();
    $( '#oimenu ul' ).css( 'position', 'absolute' );
    $( '.toggle' ).css( 'height', '0' );
}
/* ======================= HAMBURGER MENU =================================== */
function loadHamburgerMenu (): void {
    initHamburgerStyles();
    setHamburgerListeners();
}
/* --------------------------- INIT ----------------------------------------- */
/* Hide tabbed menu beneath hamburger-menu icon. */
function initHamburgerStyles (): void {
    // Remove tabbed styles
    $( 'nav' ).removeClass( 'flex-row' );
    $( '#full-menu-site-details' ).hide();
    // Set hamburger styles
    $( '#mobile-menu-bar' ).css( 'display', 'flex' );
    $( '#hdrmenu' ).addClass( 'vert' );
    $( '#oimenu' ).addClass( 'vert closed flex-col' );
    $( '.toggle' ).css( 'height', 'fit-content' );
    $( '#oimenu ul' ).css( 'position', 'relative' );
}
function setHamburgerListeners (): void {
    $( '.toggle' ).on( 'click', toggleMenu );
    $( 'li.smtrigger' ).on( 'click', toggleSubMenu );
    $( 'li.smtrigger ul a' ).on( 'click', ( e ) => e.stopPropagation() );
}
function unsetHamburgerListeners (): void {
    $( '.toggle' ).off( 'click', toggleMenu );
    $( 'li.smtrigger' ).off( 'click', toggleSubMenu );
}
/* --------------------------- TOGGLE --------------------------------------- */
function toggleMenu (): void {
    const toggle = $( '#oimenu' ).hasClass( 'closed' ) ? showMobileMenu : hideMobileMenu;
    toggle();
}
function showMobileMenu (): void {
    $( '#oimenu' ).removeClass( 'closed' );
    $( '#page-body' ).addClass( 'content-overlay' ).on( 'click', toggleMenu );
    $( '#entity-csv' ).hide(); //entity-show pages
}
function hideMobileMenu (): void {
    $( '#oimenu' ).addClass( 'closed' );
    $( '#page-body' ).removeClass( 'content-overlay' ).off( 'click', toggleMenu );
    $( '#entity-csv' ).show(); //entity-show pages
}
function toggleSubMenu ( this: HTMLElement ): void {
    if ( $( this ).hasClass( 'closed' ) ) {
        $( 'li.smtrigger' ).addClass( 'closed' ); // closes all open sub-menus
        $( this ).removeClass( 'closed' );
        this.scrollIntoView( { behavior: "smooth", block: "nearest", inline: "nearest" } );
    } else {
        $( this ).addClass( 'closed' );
    }
}