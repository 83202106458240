/**
 * Initializes the core UI for BatBase.org
 *
 * TOC
 *    GLOBALS AND STYLES
 *    AUTH-DEPENDENT INIT
 *    PAGE SPECIFIC
 */
import { isAdminUser } from '@util';
import { initUtil } from 'js/util/init';
import initFeedbackUi from './feedback/feedback';
import initHeaderAndNav from './header/site-header-main';
import * as misc from './misc';

export function initSiteCore (): void {
    misc.initSentryIssueTracking();
    setGlobalJquery();
    initUi();
    initUtil();
}
function initUi (): void {
    requireStyles();
    initLibraries();
    initHeaderAndNav();
    misc.initSiteTos();
    handlePageSpecificUiInit();
    authDependentPageInit();
}
function initLibraries() {
    require( '@libs/selectize/selectize.min.js' );
    require( 'styles/css/lib/selectize.default.css' );
    require( 'intro.js/introjs.css' );
    require( 'flatpickr/dist/flatpickr.min.css' )
}
/* ====================== GLOBALS AND STYLES ================================ */
/**
 * Necessary for access during testing, in browser development tools, and third-party
 * access generally.
 */
function setGlobalJquery (): void {
    //@ts-expect-error "Not sure why this isn't typed correctly"  //todo
    global.$ = $;
    //@ts-expect-error "Not sure why this isn't typed correctly"
    global.jQuery = $;
}
function requireStyles (): void {
    require( 'styles/ei.styl' );
}
/* ==================== AUTH-DEPENDENT INIT ================================= */
function authDependentPageInit (): void {
    const userRole = $( 'body' ).data( "user-role" );
    if ( userRole === 'visitor' ) return;
    initFeedbackUi();
    if ( ifUserCanEditPage( userRole ) ) misc.initWysiwyg( userRole );
}
function ifUserCanEditPage ( userRole: string ): boolean {
    return userRole === 'admin' && window.outerWidth > 550 || userRole === 'super';
}
/* ========================== PAGE SPECIFIC ================================= */
function handlePageSpecificUiInit (): void {
    misc.initDataTable();
    misc.initTogglePassword();
    if ( !isAdminUser() ) misc.showOverlayOnMobile();
}