/**
 * Initializes the site header with the image slider, sticky menu, and any site-
 * statistics relevant to the current page. Due to the site being primarily tested
 * and developed with the Chrome browser, users accessing with a different browser
 * have additional optimization information displayed in the header.
 *
 * Export
 *     initHeaderAndNav
 *
 * TOC
 *     OPTIMIZED BROWSER WARNING
 *     HEADER AND MAIN-NAV
 */
import { initSiteNav } from './nav';
import { initHeaderStats } from './site-stats';
import { initSlider } from './slider';
/**
 * Initializes the site header with the image slider, sticky menu, and any site-
 * statistics relevant to the current page.
*/
export default function initHeaderAndNav (): void {
    initHeaderStats();
    initCoreHeaderElems();
}
/* =================== INIT SITE-HEADER ===================================== */
function initCoreHeaderElems (): void {
    initSlider();
    initSiteNav();
}