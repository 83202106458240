/**
 * Handles all wysiwyg related code. If user is editor or above, an 'edit content'
 * button is added to the top of any page with editable content blocks.
 */
import editIcon from '@images/icons/eif.pencil.svg';
import { sendAjaxQuery } from '@util';

export function initWysiwyg ( userRole: string ): void {
    requireStyles();
    if ( $( '.wysiwyg' ).length > 0 ) { addEditContentButton( userRole ); }
}
function requireStyles (): void {
    require( 'libs/wysiwyg/trumbowyg.min.js' );
    require( 'libs/wysiwyg/ui/trumbowyg.min.css' );
}
/* ======================= DISPLAY EDIT BUTTONS ============================= */
function addEditContentButton ( userRole: string ): void {
    const button = $( '<button/>', {
        text: "Edit Content",
        id: 'editContentBttn',
        class: 'adminbttn',
        title: 'Edit Content',
        click: toggleContentBlockEditing.bind( null, userRole )
    } );
    $( '#headline-left' ).append( button );
    $( '#editContentBttn' ).data( 'editing', false );  // tracks which content block contains the active editor, if any.
}
/** Manages init and exit 'edit states' and related ui on the page. */
function toggleContentBlockEditing ( userRole: string ): void {
    const editorElem: string | boolean = $( '#editContentBttn' ).data( 'editing' );
    if ( editorElem !== false ) {
        $( '#editContentBttn' ).text( "Refreshing..." );
        location.reload();
    } else {
        addEditPencils( userRole );
        $( '#editContentBttn' ).css( { 'opacity': 1 } );
        $( '#editContentBttn' ).data( 'editing', true );
        $( '#editContentBttn' ).text( "Cancel Edit" );
    }
}
/**
 * Adds edit pencil icons to the top left of every content block container,
 * any div with class 'wysiwyg', on the page.
 */
function addEditPencils ( userRole: string ): void {
    $( '.wysiwyg' ).each( ( i, el ) => {
        $( `#${ el.id }` ).append( getEditIconHTML( el ) );
        addButtons();
    } );
    /** Starts the wysiwyg editor. If 'super' admin, includes additional buttons. */
    $( '.wysiwygEdit' ).on( 'click', startWysiwyg.bind( null, userRole ) );
}
function getEditIconHTML ( block: HTMLElement ): string {
    return `<img src="${ editIcon }" id="${ block.id }-edit"
        class="wysiwygEdit" title="Edit Content" alt="Edit Content">`;
}
/**
 * Extends the Trumbowyg library to include 'save' and 'cancel' buttons
 * for the interface. The save button updates the content block in the
 * database and then refreshes the page.
 */
function addButtons (): void {
    ( function ( $ ) {
        $.extend( true, $.trumbowyg, {
            langs: {
                en: {
                    save: 'Save',
                    cancel: 'Cancel'
                },
            },
            plugins: {
                save: { // plugin name
                    init: function ( trumbowyg: JQueryTrumbowyg.Trumbowyg ) {
                        const btnDef = {
                            hasIcon: false,
                            fn: function () {
                                const blkId = trumbowyg.o.plugins!.save!.id;
                                const data = { content: $( '#' + blkId ).trumbowyg( 'html' ) };
                                const url = 'admin/contentblock/' + blkId + '/update';
                                sendAjaxQuery( data, url, () => location.reload() );
                            }
                        };

                        trumbowyg.addBtnDef( 'save', btnDef );
                    }
                }
            }
        } );
    } )( jQuery );
}
/* ========================= START WYSIWYG ================================= */
function startWysiwyg ( userRole: string, e: Event ): void {
    const containerElemId = getBlockContainerId( ( e.target as HTMLElement ).id );
    $( '#editContentBttn' ).data( 'editing', containerElemId ); // tracks which content block contains the active editor
    $( '.wysiwygEdit' ).remove(); //Removes edit pencils from the page
    initWysiwygForBlock( userRole, containerElemId );
}
function initWysiwygForBlock ( userRole: string, containerElemId: string ): void {
    $( '#' + containerElemId ).trumbowyg( {
        autogrow: false,
        btns: getButtonTags( userRole ),
        plugins: {  // options object unique to each instance of the wysiwyg.
            save: {
                id: containerElemId
            }
        },
        svgPath: require( 'libs/wysiwyg/ui/icons.svg' )
    } );
}
/** Returns the block container id by removing '-edit' from the passed editId */
function getBlockContainerId ( editId: string ): string {
    const elemIdAry = editId.split( '-' );
    elemIdAry.pop();
    return elemIdAry.join( '-' );
}
function getButtonTags ( userRole: string ): ( string | string[] )[] {
    const buttons = [
        [ 'formatting' ],
        'btnGrp-semantic',
        [ 'link' ],
        'btnGrp-justify',
        'btnGrp-lists',
        [ 'horizontalRule' ],
        [ 'save' ]
    ];
    if ( userRole === "super" ) buttons.splice( 6, 0, [ 'viewHTML', 'removeformat' ] );
    return buttons;
}