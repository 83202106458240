/*
 * Handles sizing the image-slider in the site-header.
 *
 * Export
 *     initSlider
 */
import { getElemHeight } from "@elems";
import { handleStickyNav } from './nav';
/* Present during window resizing. */
let resizing: boolean;

export function initSlider (): void {
    initSliderContainerStyles();
    setSlideInterval();
    window.addEventListener( 'resize', resetContentAndSliderHeight );
}
function resetContentAndSliderHeight (): void {
    if ( resizing ) return;
    resizing = true;
    window.setTimeout( () => {
        resizing = false;
        setSliderAndContentSize();
        handleStickyNav();
    }, 2000 );
}
function initSliderContainerStyles (): void {
    window.setTimeout( () => {
        setSliderAndContentSize();
    }, 700 );
}
/**
 * Sets slider height based on absolute positioned child image.
 * On mobile, sets the content blocks' tops value (header logo plus menu height)
 */
function setSliderAndContentSize (): void {
    const imgHeight = getElemHeight( '#img-slider img:nth-child(1)' );
    if ( imgHeight ) { //non-mobile devices
        $( '#img-slider' ).css( 'height', imgHeight );
        $( '#slider-logo' ).css( 'height', ( imgHeight / 2 ) );
    }
    $( '#page-body' ).css( 'top', getElemHeight( '#site-header' ) );
    $( '#slider-logo' ).fadeTo( 'fast', 1 );
}
function setSlideInterval (): void {
    let cur = 1,
        next = 2;
    window.setInterval( changeSlide, 10000 );

    function changeSlide (): void {
        setSlideOpacity( next, 1 );
        window.setTimeout( afterSlideUpdated, 1000 );
    }
    function afterSlideUpdated() {
        setSlideOpacity( cur, 0 );
        cur = next;
        next = cur === 3 ? 1 : cur + 1;
    }
}
function setSlideOpacity( count: number, opacity: number ): void {
    const $slide = $( `#img-slider img:nth-child(${ count })` );
    $slide.css( { opacity: opacity } );
}