/**
 * Inits automated issue-tracking, Sentry.
 */
import * as Sentry from "@sentry/browser";
import { fetchServerData } from '@util';

window.onbeforeunload = (e) => {
    // @ts-ignore: Only set when window closed to prevent error reports from incomplete fetch calls
    window.isWindowClosed = true;
}
/* ================= SENTRY ERROR TRACKING ================================== */
export function initSentryIssueTracking (): void {
    if ( $( 'body' ).data( 'env' ) !== 'prod' ) return;
    initSentry();
}
/* --------------------- INIT SENTRY ---------------------------------------- */
function initSentry (): void {
    Sentry.init( {
        beforeSend ( event, hint ): Sentry.ErrorEvent | null {  /*Catches rouge 3rd-party error, likely google recaptcha  https://github.com/getsentry/sentry-javascript/issues/2514 */
            // @ts-ignore: Only set when window closed to prevent error reports from incomplete fetch calls
            if (window.isWindowClosed) return null;
            if ( hint && hint.originalException === "Timeout" ) return null;
            return event;
        },
        denyUrls: [ 'dev.batbase.org' ],
        dsn: 'https://28ec22ce887145e9bc4b0a243b18f94f@o955163.ingest.sentry.io/5904448',
        integrations: [
            Sentry.feedbackIntegration({
              colorScheme: "system",
              useSentryUser: {
                name: "userName",
                email: "email",
              }
            }),
            Sentry.browserTracingIntegration(),
            Sentry.captureConsoleIntegration({
                levels: ['warn', 'error']
            })
        ],
        tracesSampleRate: 0.2,  // sets a uniform sample rate
        tunnel: $('body').data('base-url') + "sentry/tunnel",
    } );
    setupSentryUser();
}
function setupSentryUser (): void {
    const user = {
        role: $( 'body' ).data( 'user-role' )
    };
    if ( user.role === 'visitor' ) return Sentry.setUser( user );
    fetchUserEmailAndSetSentryUser(user);
}
async function fetchUserEmailAndSetSentryUser(user: any): Promise<void> {
    const json = await fetchServerData<{email: string}>( 'user-email' );
    const email = json instanceof Error ? 'Email' : json.email;
    setSentryUser(user, email);
}
function setSentryUser(user: any, email: string): void {
    user.userName = $( 'body' ).data( 'user-name' );
    user.email = email;
    Sentry.setUser( user );
}