/**
 * Handles displaying and submitting the user-feedback form.
 *
 * TOC
 *     MENU ITEM
 *     POPUP
 *         SHOW
 *         HEADER
 *         FIELD ROWS
 *             RELOCATE ALERT
 *             TOPIC FIELD
 *             FEEDBACK-TEXTAREA
 *         FEEDBACK FOOTER
 *             TOGGLE SUBMIT
 *             SUBMIT FEEDBACK
 *             SENTRY ALERT
 *         CLOSE POPUP
 */
import * as el from '@elems';
import { alertIssue, sendAjaxQuery } from "@util";
/* ======================= MENU ITEM ======================================== */
/** Creates the "Leave Feedback" menu option for all registered users. */
export default function initFeedbackUi (): void {
    if ( $( 'body' ).data( 'base-url' ) == "false" ) return;
    addFeedbackButtonToUserMenu();
    require( 'styles/modules/user-feedback.styl' );
}
function addFeedbackButtonToUserMenu (): void {
    const feedbackEl = '<li id="feedback-menu"><a href="#">Leave Feedback</a></li>';
    $( '#oimenu>.last>ul' ).prepend( feedbackEl );
    $( '#feedback-menu' ).on( 'click', showFeedbackPopup );
}
/* ======================= POPUP ============================================ */
function showFeedbackPopup (): void {
    el.showPagePopup( 'feedback-popup', null, getFeedbackElems(), initCharCount );
}
/* ------------------------- SHOW ------------------------------------------- */
function getFeedbackElems (): ( string | HTMLElement )[] {
    const elems = [
        getFeedbackHeader(),
        getTopicField(),
        getContentField(),
        getFeedbackFooter()
    ];
    return elems;
}
function initCharCount (): void {
    $( '.feedback-popup textarea, .feedback-popup input' ).trigger( 'keyup' );
}
/* ------------------------- HEADER ----------------------------------------- */
function getFeedbackHeader (): string {
    const thanks = 'Thank you for contributing to Bat Eco-Interactions!';
    return `<center><h3>${ thanks }</h3></center>`;
}
/* _________________________ FIELD ROWS _____________________________________ */
function getFeedbackFieldRow ( config: el.FieldConfig, input: HTMLElement ): HTMLElement {
    const row = el.getFieldElems( config, input );
    moveAlertsToRightOfLabel( row );
    return row;
}
/* -------------------- RELOCATE ALERT -------------------------------------- */
function moveAlertsToRightOfLabel ( row: HTMLElement ): void {
    const alertEl = getAlertEl( row );
    const labelEl = getFieldLabel( row );
    if ( !alertEl || !labelEl ) return;
    $( labelEl )
        .addClass( 'flex-row field-elems' )
        .css( { width: '100%' } )
        .append( alertEl );
}
function getAlertEl ( row: HTMLElement ): Element | null {
    return row.firstElementChild ? row.removeChild( row.firstElementChild ) : null;
}
function getFieldLabel ( row: HTMLElement ): Element | null {
    const label = row.firstElementChild?.firstElementChild;
    return label ?? null;
}
/* ------------------------- TOPIC FIELD ------------------------------------ */
function getTopicField (): HTMLElement {
    const confg = getFieldConfig( 'Topic',  5, 50 );
    return getFeedbackFieldRow( confg, getTopicInput() );
}
function getTopicInput (): HTMLElement {
    const attrs = { type: 'text', placeholder: 'Brief summary of your feedback' };
    return el.getElem( 'input', attrs );
}
/* ----------------------- FEEDBACK-TEXTAREA -------------------------------- */
function getContentField (): HTMLElement {
    const config = getFieldConfig( 'Feedback', 10, 500 );
    return getFeedbackFieldRow( config, getContentTextarea() );
}
function getContentTextarea (): HTMLElement {
    const attrs = { placeholder: 'Have an idea? Find a bug? Love a feature? Let us know!' };
    return el.getElem( 'textarea', attrs );
}
/* ------------------------- FIELD CONFIG ----------------------------------- */
function getFieldConfig ( name: string, min: number, max: number ): el.FieldConfig {
    return {
        flow: 'col',
        name: name,
        val: {
            charLimits: {
                min: min,
                max: max,
                onValid: toggleFeedbackSubmitButton,
                onInvalid: toggleFeedbackSubmitButton.bind( null, false )
            }
        }
    };
}
/* ====================== FEEDBACK FOOTER =================================== */
function getFeedbackFooter (): HTMLElement {
    const config = {
        formName: 'Feedback',
        onSubmit: postFeedback,
        submitText: 'Submit Feedback',
        onCancel: closePopup
    };
    return el.getFormFooter( config );
}
/* ----------------------------- TOGGLE SUBMIT ------------------------------ */
function toggleFeedbackSubmitButton ( enable = true ): void {
    enable = enable && $( '.feedback-popup .alert-active' ).length === 0;
    const opac = enable ? 1 : .35;
    $( '#Feedback-submit' ).fadeTo( 'fast', opac ).attr( { 'disabled': !enable } );
}
/* ---------------------------- SUBMIT FEEDBACK ----------------------------- */
function postFeedback (): void {
    const data = {
        route: $( 'body' ).data( 'this-url' ),
        topic: $( '#Topic_f input' ).val() as string,
        feedback: $( '#Feedback_f textarea' ).val() as string
    };
    closePopup();
    sendAjaxQuery( data, 'feedback/post' );
    alertIssue( 'feedback', data );
}
/* =========================== CLOSE POPUP ================================== */
function closePopup (): void {
    el.hidePagePopup();
}